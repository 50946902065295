/**
 * Loads and configures the Civic cookie tool
 */

(function () {

  //var acceptCrazyEgg = function () {
  //  var script = document.createElement("script");
  //  script.async = true;
  //  script.src = '//script.crazyegg.com/pages/scripts/0010/7510.js';
  //  document.body.appendChild(script);
  //}

  var acceptTagManager = function () {
    // tag manager
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-5FL637S');
    
  };

  //var acceptRingCetral = function(){
  //  (function(d) {
  //    var cm = d.createElement('scr' + 'ipt'); cm.type = 'text/javascript'; cm.async = true;
  //    cm.src = 'https://lte-group.dimelochat.com/chat/737ff21869e58d641d1402e5/loader.js';
  //    var s = d.getElementsByTagName('scr' + 'ipt')[0]; s.parentNode.insertBefore(cm, s);
  //  }(document));
  //}

  var revokeTagManager = function () {

    var cookies = [
    '_gat_gtag_UA_2818637_42',
    '_gid',
    '_ga'
  ];

    cookies.forEach(function (cookie) {
      CookieControl.delete(cookie);
    });

  };

  var config = {
    apiKey: '9728c8367e6e58d9ad9ce9126ed3355fc73aa45f',
    product: 'PRO_MULTISITE',

    necessaryCookies: [
      'has_js', 'banner_dismissed', 'first_visit', 'csrftoken', 'sessionid'
    ],

    statement: {
      description: 'For more information please see our',
      name: 'Privacy statement',
      url: 'https://www.ucenmanchester.ac.uk/legal',
      updated: '24/01/2019'
    },

    optionalCookies: [{
      name: 'analytics',
      label: 'Analytics / Marketing',
      description: '<p>Analytics cookies are used to collect information about how visitors use our site. We use the information to compile reports and to help us improve the site. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from and the pages they visited.</p> <p>We use marketing cookies to help us improve the relevancy of advertising campaigns you receive. For more information visit our <a href="https://www.ucenmanchester.ac.uk/legal">Privacy and Cookies page</a>.</p>',
      cookies: ['CONSENT', 'NID', 'SAPISID', 'SSID',
        '_ga', '_gat', '_gid', '_gcl_au', '_gat*',
        '_ceg.s', '_ceg.u', 'cean'
      ],
      onAccept: function () {
        //        acceptCrazyEgg()
                acceptTagManager();
        //        acceptRingCetral()
      },
      onRevoke: function () {
        revokeTagManager();
      },
      recommendedState: true
    }],
    initialState: 'notify',
    layout: 'popup',
    theme: 'DARK',
    position: 'right',
    text: {
      accept: 'Accept Recommended Settings',
      settings: 'Preferences',
      notifyDescription: 'We use cookies on our website to allow us to continuously improve our site and enhance your experience. You can use this tool to easily manage your cookie preferences, otherwise please click <strong>Accept Recommended Settings</strong> to let us know you\'re happy for us to continue',
      intro: 'Some of these cookies are essential, while others help us to improve your experience by providing insights into how the site is being used.<br /> By clicking <strong> Accept Recommended Settings </strong> you are consenting to the use of cookies while you\'e on our website',
      necessaryDescription: 'Necessary cookies enable core functionality such as page navigation. The website cannot function properly without these cookies, and can only be disabled by changing your <a href="/legal"><strong>browser preferences</strong>.</a>'
    },

    branding: {
      fontFamily: '"Montserrat", sans-serif;',
      fontColor: "#fff",
      fontSizeTitle: "1.3em",
      fontSizeIntro: "1.1em",
      fontSizeHeaders: "1.1em",
      fontSize: "0.9em",
      acceptText: '#fff',
      acceptBackground: "#0084c9",

      backgroundColor: "#3c3c3b",
      toggleText: "#fff",
      toggleColor: "#3c3c3b",
      toggleBackground: "#0084c9",
      buttonIcon: null,
      buttonIconWidth: "64px",
      buttonIconHeight: "64px",
      removeIcon: true,
      removeAbout: true
    }
  };

  var loadCookieControl = function (config) {
    var cookieControlScript = document.createElement('script');
    cookieControlScript.src = 'https://cc.cdn.civiccomputing.com/8/cookieControl-8.x.min.js';
    cookieControlScript.async = true;

    document.body.appendChild(cookieControlScript);

    cookieControlScript.onload = function () {
      CookieControl.load(config);
    };
  };

  loadCookieControl(config);

})();