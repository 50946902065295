import '../styles/index.scss';

import 'jquery-confirm/css/jquery-confirm.css';

import 'lite-youtube-embed/src/lite-yt-embed.css';
import 'lite-youtube-embed/src/lite-yt-embed.js';
import 'imports-loader?imports=default|jquery|$!owl.carousel';
import 'popper.js';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';

import flatpickr from "flatpickr";
require('flatpickr/dist/flatpickr.min.css');

import './text-resize';
import './addtohomescreen.js';
import './civic_config.js';

var Mustache = require('mustache');
var jconfirm = require('jquery-confirm');

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

$(document).ready(function () {
  onPageLoad();
});

const body_ele = document.querySelector('body');
body_ele.addEventListener('cms_refresh', function (e) {
  console.log('js refresh catch');
  onPageLoad();
}, false);

function onPageLoad() {
  flatpickr('.dateinput', {
    dateFormat: 'd/m/Y'
  });
//  var ath = addToHomescreen({
//    debug: 'android',           // activate debug mode in ios emulation
//    skipFirstVisit: false,	// show at first access
//    startDelay: 0,          // display the message right away
//    lifespan: 0,            // do not automatically kill the call out
//    displayPace: 0,         // do not obey the display pace
//    privateModeOverride: true,	// show the message in private mode
//    maxDisplayCount: 0      // do not obey the max display count
//  });

  addToHomescreen({
    detectHomescreen: true,
    lifespan: 30
  });

  jQuery.fontSizer({selector:".content-wrapper"});

  var debounceTabletStructure = debounce(function() {
    let pad_top = 40;
    let pad_intial = 10;
    let right_pos = 0;
    let mobile_breakpoint = 768;

    let current_top_pos = pad_intial;

    let media_query = window.matchMedia("(max-width: 767px)");

    if(media_query.matches) {
      $('section.tablet-right').removeClass('positioned');
    }else{
      $('section.tablet-right').each(function() {
        let sec = $(this);
        let height = sec.height();

        if(!sec.hasClass('positioned')) {
          sec.addClass('positioned');
          sec.css('right', right_pos);
          sec.css('top', current_top_pos + 'px');

          current_top_pos = current_top_pos + height + pad_top;
        }
      });
    }
  }, 250);

  debounceTabletStructure();

  window.addEventListener('resize', () => {
    debounceTabletStructure();
  });

  var debounceSearch = debounce(function() {
    $.ajax({
      url: '/courses/search/',
      data: {
        terms: $('#course_terms').val(),
        subject_area_id: $('#subject_area_id').val()
      }
    }).done(function(response) {
      var template = document.getElementById('course_search_tmpl').innerHTML;
      var rendered = Mustache.render(template, { courses: response });
      $('#course_results').html(rendered);

    }).fail(function() {
      $('#course_results').html("Couldn't load results");
    }).always(function() {

    });
  }, 250);

  function setActiveFilters() {
    let type_filter = $('#type_filter option:selected').text();
    let sa_filter = $('#sa_filter option:selected').text();
    const all = 'All';

    $('.active-filters').text('');
    let filter_text = '';
    if(type_filter != all || sa_filter != all) {
      filter_text = 'Searching';

      if (type_filter !== all) {
        filter_text += ` for ${type_filter}s`;
      }

      if (sa_filter !== all) {
        filter_text += ` in ${sa_filter}`;
      }

      $('.active-filters').text(filter_text);
    }
  }

  var debounceAllSearch = debounce(function(page) {
    //page represents page no of result set
    let load_more = (page === undefined) ? false : true;
    let next_page = null;
    if(load_more) {
      next_page = parseInt(page) + 1;
    }

    let type_filter = $('#type_filter').val();
    let sa_filter = $('#sa_filter').val();

    setActiveFilters();

    $.ajax({
      url: '/prospectus/search/',
      data: {
        terms: $('#all_terms').val(),
        search_page: next_page,
        type_filter: type_filter,
        sa_filter: sa_filter
      },
      beforeSend: function(xhr) {
        if(!load_more) {
          $('.search-all-wrapper .results-wrapper').hide();
        }
        $('.search-all-wrapper .loader').show();
        $('.search-all-wrapper .load-more').hide();

        $('#error').hide();
      }
    }).done(function(response) {
      var template = document.getElementById('all_search_tmpl').innerHTML;
      var rendered = Mustache.render(template, { results: response.results });

      if(!load_more) {
         $('#all_results').empty();
      }

      $('#all_results').append(rendered);
      $('.search-all-wrapper .load-more').attr('data-current_page', response.page);
      $('.search-all-wrapper .results-wrapper').show();

      if(response.page !== response.total_pages) {
        $('.search-all-wrapper .load-more').show();
      }

    }).fail(function() {
      $('#error').show();
    }).always(function() {
      $('.search-all-wrapper .loader').hide();
    });
  }, 500);

  $('#course_terms').on('keyup', function() {
    debounceSearch();
  });

  $('.course-form').on('submit', function(e) {
    e.preventDefault();
    debounceAllSearch();
  });

  $('#all_terms').on('keyup', function(e) {
    e.preventDefault();
    debounceAllSearch();
  });

  $('.search-form').on('submit', function(e) {
    e.preventDefault();
    debounceAllSearch();
  });

  $('.search-all-wrapper #type_filter, .search-all-wrapper #sa_filter').on('change', function() {
    debounceAllSearch();
  });

  $('.search-all-wrapper .load-more').on('click', function() {
    let current_page = $(this).data('current_page');
    debounceAllSearch(current_page);
  });

  $('.search-all-wrapper .reset').on('click', function() {
    $('.search-all-wrapper .all-results').empty();
    $('.search-all-wrapper .results-wrapper').hide();
  });

  $('.user-wrapper .button-wrapper').on('click', function() {
    $('.user-wrapper').hide();
    $('.account-form').show();
  });

  $('.account-form .back-btn').on('click', function() {
    $('.user-wrapper').show();
    $('.account-form').hide();
  });

  // from courses page add to my courses button
  var debounceAddCourse = debounce(function() {
    $.ajax({
        method: 'POST',
        url: '/courses/my_courses/add/',
        data: {
            course_id: $('.course-detail-wrapper .my-course-bar').data('course_id'),
            subcourse_id: $('.subcourse-list .list-group-item.selected').data('subcourse_id')
        },
        beforeSend: function(xhr) {
            $('.sa-picker-wrapper .status').text('saving');
            xhr.setRequestHeader("X-CSRFToken", $('input[name=csrfmiddlewaretoken]').val());
        }
    }).done(function(response) {
      $('.course-detail-wrapper .add').hide();
      $('.course-detail-wrapper .added').show();

    }).fail(function() {
      $('#course_results').html("Couldn't load results");
    }).always(function() {

    });
  }, 250);

  $('.course-detail-wrapper .my-course-bar').on('click', function() {
    debounceAddCourse(this);
  });

  // add fav from search result star
  var debounceSearchAddCourse = debounce(function(ele) {
    let result_ele = $(ele).closest('.course-result');
    let loader = $(ele).next('.loader');
    let action = $(ele).data('action');

    $.ajax({
        method: 'POST',
        url: `/courses/my_courses/${action}/`,
        data: {
            course_id: $(result_ele).data('course_id')
        },
        beforeSend: function(xhr) {
            $(ele).removeClass('error');
            loader.show();
            $(ele).hide();
            xhr.setRequestHeader("X-CSRFToken", $('input[name=csrfmiddlewaretoken]').val());
        }
    }).done(function(response) {
      if(action == 'add') {
        $(result_ele).find('.added').show();
      }else{
        $(result_ele).find('.not-added').show();
      }
    }).fail(function() {
      $(ele).addClass('error').show();
    }).always(function() {
      loader.hide();
    });
  }, 250);

  $('#course_results').on('click', '.course-result .fav', function(e) {
    e.preventDefault();
    let logged_in = $('#logged_in').val();
    if(logged_in === 'True') {
      debounceSearchAddCourse(this);
    }else{
      $.confirm({
        title: 'Favorite Course',
        content: 'You must be logged in to favorite courses',
        type: 'blue',
        buttons: {
          ok: {
            text: "Login",
            btnClass: 'btn-primary',
            keys: ['enter'],
            action: function(){
              window.location.href = 'http://localhost:8113/preferences/';
            }
          },
          cancel: function(){}
        }
      });
    }
  });

  var debounceRemoveCourse = debounce(function(remove_course_ele) {
    let course_id = $(remove_course_ele).data('course_id');

    $.confirm({
        title: 'Remove Course',
        content: 'Are you sure',
        type: 'blue',
        buttons: {
            ok: {
                text: "Yes",
                btnClass: 'btn-primary',
                keys: ['enter'],
                action: function(){
                  $.ajax({
                      method: 'POST',
                      url: '/courses/my_courses/remove/',
                      data: {
                          course_id: course_id
                      },
                      beforeSend: function(xhr) {
                          xhr.setRequestHeader("X-CSRFToken", $('input[name=csrfmiddlewaretoken]').val());
                          $(remove_course_ele).hide();
                          $(remove_course_ele).next().show();
                      }
                  }).done(function(response) {
                    $(remove_course_ele).closest('.course').hide();
                  }).fail(function() {
                    console.log('error');
                  });
                }
            },
          cancel: function(){
          }
        }
    });
  }, 250);

  $('.course-list .course .remove').on('click', function() {
    debounceRemoveCourse(this);
  });

  var debounceAddBookmark = debounce(function(ele) {
    var action = $(ele).data('action');

    $.ajax({
        method: 'POST',
        url: `/prospectus/bookmark/${action}/`,
        data: {
            page_id: $('.bookmark').data('page_id')
        },
        beforeSend: function(xhr) {
            $('.bookmark .bookmark-icon').hide();
            $('.bookmark .loader').show();
            xhr.setRequestHeader("X-CSRFToken", $('input[name=csrfmiddlewaretoken]').val());
        }
    }).done(function(response) {
      if(action == 'add') {
        $('.bookmark .is_bookmarked').show();
      }else{
        $('.bookmark .not_bookmarked').show();
      }

      $('.bookmark').removeClass('error');
    }).fail(function() {
      if(action == 'add') {
        $('.bookmark .not_bookmarked').show();
      }else{
        $('.bookmark .is_bookmarked').show();
        $('.bookmark').addClass('error');
      }
    }).always(function() {
      $('.bookmark .loader').hide();
    });
  }, 250);

   $('.bookmark-icon').on('click', function() {
    debounceAddBookmark(this);
  });

  $('.ss-picker-wrapper .support-service').on('click', function() {
    var selected = [];
    $(this).toggleClass('selected');

    $('.ss-picker-wrapper .support-service').each(function() {
        if($(this).hasClass('selected')) {
            selected.push($(this).data('ss_id'));
        }
    });

    debounceSubmitSS(selected);
  });

  var debounceSubmitSS = debounce(function(ids) {
    $.ajax({
      type: 'POST',
      url: '/user/api/support_services_update/',
      data: {
        ss_ids: ids.join(',')
      },
      beforeSend: function(xhr) {
        $('.pref-list .ss .icon').hide();
        $('.pref-list .ss .loader').show();
        xhr.setRequestHeader("X-CSRFToken", $('input[name=csrfmiddlewaretoken]').val());
      }
    }).done(function(response) {
      $('.pref-list .ss .icon').removeClass('error');
    }).fail(function() {
      $('.pref-list .ss .icon').addClass('error');
    }).always(function() {
      $('.pref-list .ss .icon').show();
      $('.pref-list .ss .loader').hide();
    });
  }, 500);

  $('.sa-picker-wrapper .subject-area-item').on('click', function() {
    $(this).toggleClass('selected');
    $('.sa-picker-wrapper .show-all').removeClass('selected');

    debounceSubmitSA();
  });

  $('.sa-picker-wrapper .show-all').on('click', function() {
    let is_selected = $(this).hasClass('selected');
    $(this).toggleClass('selected');

    $('.sa-picker-wrapper .subject-area-item').each(function() {
      if(!is_selected) {
        $(this).addClass('selected');
      }else{
        $(this).removeClass('selected');
      }
    }).promise().done(function(){
      debounceSubmitSA();
    });
    
  });

  var debounceSubmitSA = debounce(function() {
    var selected = [];
    $('.sa-picker-wrapper .subject-area-item').each(function() {
      if($(this).hasClass('selected')) {
        selected.push($(this).data('sa_id'));
      }
    });

    $.ajax({
      type: 'POST',
      url: '/user/api/subject_area_update/',
      data: {
        subject_area_ids: selected.join(',')
      },
      beforeSend: function(xhr) {
        $('.pref-list .sa .icon').hide();
        $('.pref-list .sa .loader').show();
        xhr.setRequestHeader("X-CSRFToken", $('input[name=csrfmiddlewaretoken]').val());
      }
    }).done(function(response) {
      $('.pref-list .sa .icon').removeClass('error');
    }).fail(function() {
      $('.pref-list .sa .icon').addClass('error');
    }).always(function() {
      $('.pref-list .sa .icon').show();
      $('.pref-list .sa .loader').hide();
    });
  }, 500);

  var debounceSubmitUserDetails = debounce(function() {
    let form_data = $('.account-form').serializeArray();

    $.ajax({
      type: 'POST',
      url: '/user/api/user_details/',
      data: form_data,
      beforeSend: function(xhr) {
        $('#save_button').val('Saving...');
        xhr.setRequestHeader("X-CSRFToken", $('input[name=csrfmiddlewaretoken]').val());
      }
    }).done(function(response) {
      $('.user-wrapper').show();
      $('.account-form').hide();

      $('.user-wrapper .name').text($('#id_first_name').val() + ' ' + $('#id_last_name').val());
      $('.user-wrapper .email').text($('#id_email').val());

      $('#save_button').val('Update');
    }).fail(function() {
      $('#save_button').val('Error').addClass('error');
    });
  }, 500);

  $('.account-form').on('submit', function(e) {
    e.preventDefault();
    debounceSubmitUserDetails();
  });

  var debounceRemoveBookmark = debounce(function(remove_book_ele) {
    let page_id = $(remove_book_ele).data('page_id');

    $.confirm({
        title: 'Remove Bookmark',
        content: 'Are you sure',
        type: 'blue',
        buttons: {
            ok: {
                text: "Yes",
                btnClass: 'btn-primary',
                keys: ['enter'],
                action: function(){
                  $.ajax({
                      method: 'POST',
                      url: '/prospectus/bookmark/remove/',
                      data: {
                          page_id: page_id
                      },
                      beforeSend: function(xhr) {
                          xhr.setRequestHeader("X-CSRFToken", $('input[name=csrfmiddlewaretoken]').val());
                          $(remove_book_ele).hide();
                          $(remove_book_ele).next().show();
                      }
                  }).done(function(response) {
                    $(remove_book_ele).closest('.page').hide();
                  }).fail(function() {
                    console.log('error');
                  });
                }
            },
          cancel: function(){
          }
        }
    });
  }, 250);

  $('.bookmark-list .page .remove').on('click', function() {
    debounceRemoveBookmark($(this));
  });

  $('.subcourse-list .list-group-item').on('click', function() {
    $('.subcourse-list .list-group-item').removeClass('selected');
    $(this).addClass('selected');
  });

  $('.subcourse-apply-btn').on('click', function() {
    var url = $('.subcourse-list .list-group-item.selected').data('apply_url');
    if(url) {
      window.open(url, '_blank').focus();
    }
  });

  $('.share-button').on('click', function() {
    var url = $(this).data('page_url');
    var title = $(this).data('page_title');

    $('.page-share-popup').remove();
    var template = document.getElementById('share_tmpl').innerHTML;
    var rendered = Mustache.render(template, {
      url: url,
      title: title
    });
    $('.share-wrapper').append(rendered);

  });

  $('.share-wrapper').on('click', '.close', function() {
    $('.page-share-popup').removeClass("show");
  });

  $('.share-wrapper').on('click', '.field button', function() {
    const field = $(".page-share-popup .field"),
    input = $(".page-share-popup .field input"),
    copy = $(".page-share-popup .field button");

    input[0].select(); //select input value
    if (document.execCommand("copy")) { //if the selected text copy
      field.addClass("active");
      copy.text("Copied");
      setTimeout(() => {
        window.getSelection().removeAllRanges(); //remove selection from document
        field.removeClass("active");
        copy.text("Copy");
      }, 3000);
    }
  });

};
