(function($){
  //http://jsfiddle.net/skibulk/eh5xr0z3/22/
    $.fontSizer = function(options) {
        // Load Options
        var opt = $.extend({
            selector:      "body",
            sizeMaximum:    20,
            sizeDefault:    14,
            sizeMinimum:    10,
            sizeInterval:   2,
            buttonIncrease: ".font-sizer .increase",
            buttonDecrease: ".font-sizer .decrease",
            buttonReset:    ".font-sizer .reset",
        }, options);

        // Initialize
        $(opt.buttonIncrease).click(increase);
        $(opt.buttonDecrease).click(decrease);
        $(opt.buttonReset).click(reset);
        if($.hasOwnProperty('cookie')) {
          render( $.cookie('font-size') );
        }else{
          render( opt.sizeDefault );
        }

        // Increase Handler
        function increase() {
            size = parseFloat($(opt.selector).css("font-size"));
            size = Math.min(size + opt.sizeInterval, opt.sizeMaximum);
            render(size);
        }

        // Decrease Handler
        function decrease() {
            size = parseFloat($(opt.selector).css("font-size"));
            size = Math.max(size - opt.sizeInterval, opt.sizeMinimum);
            render(size);
        }

        // Reset Handler
        function reset() {
            render(opt.sizeDefault);
        }

        // Render
        function render(size) {
            $(opt.selector).css("font-size", size +"px");
            $(opt.buttonIncrease).prop( "disabled", (size >= opt.sizeMaximum) );
            $(opt.buttonDecrease).prop( "disabled", size <= opt.sizeMinimum );
            $(opt.buttonReset).prop( "disabled", (size == opt.sizeDefault) );
            if($.hasOwnProperty('cookie')) {
              $.cookie('font-size', size);
            }
        }
    };
})(jQuery);